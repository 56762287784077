import React from "react"
import { Link } from "gatsby"
import Sidebar from '../../components/sidebar'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import chargeursansfil from "../../assets/img/chargeursansfil.jpg"



const chargeurSansFilIntegrePage = () => (
  <Layout>
    <SEO title="chargeur sans fil intégré" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div class="grid grid-cols-1 gap-2 md:col-span-3 md:my-5 mx-3"> {/** first element */}
             <h1 class="text-2xl font-bold mb-8 divider">Chargeur sans fil intégré dans le plan en Solid Surface et iPhone avec coque spéciale</h1>
            <img class="mb-0 mx-auto w-full" src={chargeursansfil} alt="chargeur sans fil intégré" />
            <p class="text-justify">
            Le chargeur sans fil gagne de plus en plus de popularité. Nous vous avons déjà montré quelques meubles et accessoires intéressants, équipés de la technologie Qi permettant le chargement sans fil, pratique de nos smartphones et nos tablettes. D’autre côté nous avons Du Pont -la société qui possède le brevet pour la matière minérale Corian – compagnie qui depuis longtemps est connue pour son ingéniosité et ses recherches innovantes. Qu’est-ce qui se passe quand on combine les deux? Lors de l’Expo NeoConvergence en été 2015, Du Pont a présenté `sa dernière invention – le Corian 2.0. Autrement dit, c’est un plan de travail en Corian, muni de chargeur sans fil intégré qui a plusieurs applications utiles.

            Explorons cette création remarquable! Presque tout le monde connaît déjà les nombreux avantages du plan de travail en Corian. Sa durabilité et son entretien facile ne sont que quelques-unes des raisons pour lesquelles il est vastement préféré par les concepteurs et les propriétaires contemporains. Mais savez-vous que ce n’est pas uniquement pour les plans dans la cuisine ou la salle de bains qu’on peut l’utiliser ? On en fabrique déjà toutes sortes de meubles, ce qui rend l’ajout d’un chargeur sans fil intégré dans sa structure une invention géniale dont tous les gens pourraient profiter ! Un avantage incotestable – le chargeur sans fil dans le plan en Corian n’est pas réservé uniquement à l’utilisation à domicile. Il s’applique dans les bureaux et les endroits publics tels que restaurants, cafés, ares, aéroports, banques, magasins et bibliothèques ! Les surfaces en Corian sont disponibles dans de nombreuses couleurs et textures et offrent des possibilités illimitées de design, convenables à tout intérieur. Encastré dans le comptoir en Corian, le chargeur sans fil fonctionne très simplement: l’appareil est posé sur un récepteur marqué avec des pois lumineux et doit y rester connecté au point de chargement complet. Si l’appareil n’est pas PMA ou Qi-compatible, vous avez besoin d’un récepteur séparé – une coque spéciale ou un adaptateur Powermat. Mais qui sait, peut-être à l’avenir, la technologie serait encore améliorée de sorte que ces deux éléments deviendraient également inutiles et le chargeur sans fil intégré fonctionnerait sans intermédiaire.
            </p>
       </div>
       <div>
         <Sidebar />
       </div>
    </div>
  </Layout>
  )
 
  export default chargeurSansFilIntegrePage